.labor-page {
    height: 100vh;
}

@media screen and (max-width: 768px) {
    .practice-img-container {
        width: 37rem;
    }
}

@media screen and (max-width: 540px) {
    .practice-img-container {
        width: 24rem;
    }
    
    .practice-img {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .practice-img-container {
        width: 18rem;
    }
    
    .practice-img {
        width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .practice-img-container {
        width: 14rem;
        margin: auto;
    }
}

@media screen and (max-width: 280px) {
    .practice-img-container {
        width: 9rem;
        margin: auto;
    }
}

