@media screen and (min-height: 1366px) {
    .general-practice-img-container {
        width: 25rem;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {
    .general-practice-img-container {
        width: 20rem;
        margin: auto;
    }
}

@media screen and (max-width: 414px) {
    .general-practice-img-container {
        width: 18rem;
    }
    
    .practice-img {
        width: 100%;
    }
}

@media screen and (max-width: 375px) {
    .general-practice-img-container {
        width: 14rem;
        margin: auto;
    }
}

@media screen and (max-width: 280px) {
    .general-practice-img-container {
        width: 9rem;
        margin: auto;
    }
}