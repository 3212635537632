.body-container p {
    line-height: 1.6;
    color: white;
}

h1 {
    text-align: center;
    color: white;
    margin-bottom: 1rem;
}

.about-elvin-container {
    width: 9rem;
    float: left;
    margin: 1rem;
}

.about-elvin-img {
    width: 100%;
}

.about-img-container {
    width: 100vw;
    height: 300px;
    background: url('../../images/gavel.jpg') no-repeat;
    background-size: cover;
    background-position-y: 50%;
}

@media screen and (min-height: 1366px) {
    .about-title {
        left: 23%;
    }
}

@media screen and (max-width: 768px) {
    .about-title {
        top: 14%;
        left: 14%;
    }
}

@media screen and (max-width: 540px){
    .about-title {
        top: 15%;
        left: 0;
    }
}

@media screen and (max-width: 414px) {
    .about-elvin-container {
        float: none;
        margin: 1rem auto;
        width: 12rem;
    }
}

@media screen and (max-width: 280px) {
    .about-page {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .about-elvin-container {
        width: 8rem;
    }

    .about-title {
        top: 19%;
    }
}
