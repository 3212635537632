.news-page {
    padding: 7rem 4rem;
}

.blog-wrapper {
    background-color: white;
    border-radius: 10px;
    box-shadow: 4px 4px black;
    margin: 2rem 0;
    padding: 2rem;
}

.blog-container {
    padding: 0.5rem 0;
    border-bottom: 2px solid black;
}

.blog-title {
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
}