.initial-page {
    background: #0b1c2b; 
    padding: 3rem 4rem;
 }

.justice-container {
    width: 60rem;
    margin: 2rem auto;
}

.justice-img {
    width: 100%;
}

.initial-title {
    text-shadow: 4px 4px 2px black;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
}

.second-title {
    margin: 3rem 0;
}

.initial-img-container {
    width: 100vw;
    height: 700px;
    background: url('../../images/courthouse_with_scales_of_justice.jpg') no-repeat;
    background-size: cover;
}

.initial-hero-overlay {
    position: absolute;
    z-index: 2;
    background-color: white;
    opacity: 0.4;
    height: 700px;
    width: 100vw;
}

.initial-hero-title {
    color: white;
    position: absolute;
    top: 40%;
    left: 6%;
    z-index: 3;
    text-shadow: 4px 4px 2px black;
    font-size: 4rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

@media screen and (min-height: 1366px) {
    .initial-img-container {
        background-position-x: 38%;
    }

    .initial-hero-title {
        top: 23%;
        left: 0%;
    }
}

@media screen and (max-width: 768px) {
    .initial-img-container {
        background-position-x: 66%;
    }

    .initial-hero-title {
        top: 30%;
        left: 0%;
    }
}

@media screen and (max-width: 540px){
    .initial-hero-title {
        top: 30%;
        left: 5%;
    }
}

@media screen and (max-width: 280px) {
    .initial-hero-title {
        font-size: 3rem;
    }

    .initial-title {
        font-size: 2rem;
    }

    .initial-page {
        padding: 3rem 2rem;
    }
}