.nav-bkg {
    background: url('../../images/blue_skyline.jpg') no-repeat;
    background-size: cover;
    background-position-y: 40%;
    height: 80px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0 4px 5px black;
}

.nav-overlay {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 80px;
    background-color: black;
    opacity: 0.5;
}

.logo-container {
    width: 11rem;
}

.logo-img {
    width: 100%;
}

#responsive-navbar-nav .nav-button {
    color: white;
    text-align: center;
}

@media screen and (max-width: 768px) {
    .logo-container {
        width: 8rem;
    }
}

@media screen and (max-width: 540px) {
    .navbar-collapse {
        background-color: black;
        margin-top: 0.5rem;
    }

    .nav-item:hover {
        background-color: red;
    }
}

@media screen and (max-width: 414px) {
    .navbar-collapse {
        background-color: black;
        margin-top: 0.5rem;
    }

    .logo-container {
        width: 7rem;
    }
}

@media screen and (max-width: 280px) {
    .logo-container {
        width: 9rem;
    }
}

