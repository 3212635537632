.contact-page {
    background: #0b1c2b; 
    padding: 7rem 4rem;
}

.formRow {
    margin-bottom: 1rem;
}

.labels {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.3rem;
    color: white;
}

.subMessage,
.subtitle {
    color: white;
    font-style: italic;
}

@media screen and (max-width: 280px) {
    .contact-page {
        padding: 7rem 2rem;
    }
}