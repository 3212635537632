h1 {
    text-align: center;
    color: white;
    margin-bottom: 1rem;
}

hr {
    background-color: white;
}

.practice-title {
    color: white;
    font-family: serif;
    text-align: center;
    font-weight: bold;
    margin: 4rem 0 1rem 0;
}

.practice-description {
    font-size: 1rem;
}

.practice-img {
    width: 100%;
}

.practice-list {
    list-style: none;
    text-align: center;
    font-size: 1.5rem;
    margin: 3rem 0;
    font-style: italic;
}

.practice-list li {
    margin: 2rem auto;
    background-color: #de0a02;
    padding: 0.5rem;
    border-radius: 5px;
    width: 60%;
}

.practice-list li:hover {
    text-decoration: none;
    background-color: #770e0b;
    color: white;
    font-size: 1.6rem;
}

.practice-links {
    color: white;
}

.practice-links:hover {
    text-decoration: none;
    color: white;
}

.services-img-container {
    width: 100vw;
    height: 300px;
    background: url('../../images/downtown_skyline.jpg') no-repeat;
    background-size: cover;
    background-position-y: 50%;
}

@media screen and (min-height: 1366px) {
    .services-page {
        height: 100vh;
        padding-top: 17rem;
    }
}

@media screen and (max-width: 540px) {
    .practice-list {
        padding: 0;
    }
    
    .practice-list li {
        width: 100%;
    }
}

@media screen and (max-width: 414px) {
    .practice-list {
        padding: 0;
    }

    .practice-list li {
        width: 100%;
        font-size: 1rem;
    }
}

@media screen and (max-width: 280px) {
    h1 {
        font-size: 2rem;
    }

    .services-page {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}