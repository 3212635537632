.footer {
    background: #192E41;
    width: 100%;
    /* height: 80px;
    position: fixed;
    bottom: 0; */
    box-shadow: 0 -2px 3px black;
}

.footer-layout {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.footer-top {
    display: flex;
}

.footer-right {
    margin-left: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.hours-container {
    margin-top: 2rem;
}

.disclaimer-container {
    width: 70%;
    margin-top: 1rem;
}

.mobile-break {
    display: none;
}

.email,
.phone-number,
.address,
.hours,
.email a,
.consultation,
.copyright,
.fax {
    color: #D3D3D3;
}

.email a:hover {
    color: royalblue;
    text-decoration: none;
}

.email span,
.phone-number span,
.address span,
.hours span,
.fax span {
    color: red;
    font-weight: bold;
}

.disclaimer {
    font-size: 0.7rem;
    color: #D3D3D3;
}

.footer hr {
    background-color: #D3D3D3;
    width: 100%;
}

@media screen and (min-height: 1366px) {
    .footer-right {
        margin-left: 11rem;
    }
}

@media screen and (max-width: 768px) {
    .footer-right {
        margin-left: 4rem;
    }
}

@media screen and (max-width: 414px) {
    .footer-top {
        display: initial;
    }

    .footer-right {
        margin-left: initial;
    }

    .mobile-break {
        display: initial;
    }
}

@media screen and (max-width: 280px) {
    .email,
    .phone-number {
        font-size: .8rem;
    }
}