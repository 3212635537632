@media screen and (min-height: 1366px) {
    .commercial-practice-img-container {
        width: 30rem;
        margin: auto;
    }
}

@media screen and (max-width: 768px) {
    .commercial-practice-img-container {
        width: 25rem;
        margin: auto;
    }
}

@media screen and (max-width: 414px) {
    .commercial-practice-img-container {
        width: 16rem;
        margin: auto;
    }
}

@media screen and (max-width: 360px) {
    .commercial-practice-img-container {
        width: 14rem;
        margin: auto;
    }
}

@media screen and (max-width: 280px) {
    .commercial-practice-img-container {
        width: 9rem;
        margin: auto;
    }
}