.body-container {
    background: #0b1c2b; 
    padding: 3rem 4rem;
 }

.home-page {
    height: 100%;
}

h1 {
    text-align: center;
    color: white;
    margin-bottom: 1rem;
}
 
p {
    line-height: 1.6;
    color: white;
}

.home-elvin-container {
    width: 15rem;
    float: left;
    margin: 0.5rem 1rem;
}

.home-elvin-img {
    width: 100%;
}

.hero-img-container {
    width: 100vw;
    height: 300px;
    background: url('../../images/courtrom.jpg') no-repeat;
    background-size: cover;
}

.hero-overlay {
    position: absolute;
    z-index: 2;
    background-color: white;
    opacity: 0.4;
    height: 300px;
    width: 100vw;
}

.hero-title {
    color: white;
    position: absolute;
    top: 20%;
    left: 35%;
    z-index: 3;
    text-shadow: 4px 4px 2px black;
    font-size: 4rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
}

@media screen and (min-height: 1366px) {
    .home-page {
        height: initial;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .hero-title {
        top: 11%;
        left: 30%;
    }

    h1 {
        font-size: 5rem;
    }

    hr {
        width: 100%;
    }

    .home-elvin-container {
        width: 18rem;
    }

    p {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    .hero-title {
        top: 14%;
        left: 20%;
    }
}

@media screen and (max-width: 540px) {
    .hero-title {
        top: 19%;
        left: 8%;
    }
}

@media screen and (max-width: 414px) {
    .home-page {
        height: initial;
    }

    .home-elvin-container {
        float: none;
        margin: 1rem auto;
        width: 12rem;
    }

    .hero-title {
        top: 15%;
        left: 0;
    }
}

@media screen and (max-width: 280px) {
    .home-page {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .home-elvin-container {
        width: 8rem;
    }

    .hero-title {
        font-size: 3rem;
        top: 19%;
    }
}

